import { string, object, AnyObjectSchema, SchemaOf } from "yup";
import { setIn, ValidationErrors } from "final-form";
import { FormsValidationLocalization } from "../../graphql/generated";

const WRONG_EMAIL = "Email address is not valid";
const EMAIL_REQUIRED = "Your email address is required";
const ZIP_CODE_REQUIRED = "A zip code is required";
const ZIP_CODE_FORMAT = "Please enter a valid zip code format, e.g. 90210";
const ZIP_CODE_LENGTH = "Zip code cannot be longer than 5 numbers";
const FIELD_REQUIRED = "This field cannot be left blank";

/**
 * DUPLICATED FROM @pepdirect/v3/constants
 */
const validationErrorsEnglish: FormsValidationLocalization = {
  dayRequiredErrorText: "Please select a day",
  emailRequiredErrorText: "Your email address is required",
  fieldRequiredErrorText: "This field cannot be left blank",
  invalidDateErrorText: "Please enter a valid date.",
  invalidPasswordErrorText: "Invalid password",
  invalidPhoneErrorText: "Invalid phone number",
  monthRequiredErrorText: "Please select a month",
  newPasswordRequiredErrorText:
    "Please enter a new password to use for your account",
  passwordRequiredErrorText: "Your password is required",
  requiredErrorText: "Required",
  signupPasswordRequiredErrorText:
    "Please enter a password to use for your account",
  wrongEmailErrorText: "Email address is not valid",
  yearRequiredErrorText: "Please select a year",
  invalidTaxIdErrorText: "Invalid Tax ID",
  invalidTaxIdLengthErrorText: "Must be exactly 10 digits",
};

/**
 * DUPLICATED FROM @pepdirect/v3/helpers/localization
 */
export const getLocalizedValidationMessage = (
  localizationKey: keyof FormsValidationLocalization,
  localization?: FormsValidationLocalization | null
) =>
  localization?.[localizationKey] || validationErrorsEnglish[localizationKey];

/**
 * Additional regex needed on top of Yup's built-in email method, it DOES NOT match for email pattern
 *
 * ^([\x20-\x7E]+)$
 * - Regex for English characters (includes asci 32 ` ` space to 126 `~` tilde)
 * - This helps to prevent users from potentially typing email address like bjørn@example.com
 *
 * (?!.*`)
 * - Negative lookahead to not match on backtick
 * - can add more chars if needed after the backtick in the expression
 */
export const regexEmailHelper = /(?!.*`)^([\x20-\x7E]+)$/gm;

// NO LOCALIZATION FOR NOW, ONLY USED IN SHOPS
export const loginSchema = object().shape({
  login: string()
    .trim()
    .email(WRONG_EMAIL)
    .matches(regexEmailHelper, {
      excludeEmptyString: true,
      message: WRONG_EMAIL,
    })
    .required(EMAIL_REQUIRED),
  password: string().trim().required("Your password is required"),
});

// NO LOCALIZATION FOR NOW, ONLY USED IN SHOPS
export const zipCodeSchema = object({
  zipCode: string()
    .trim()
    .max(5, ZIP_CODE_LENGTH)
    .matches(/\d{5}/, {
      message: ZIP_CODE_FORMAT,
    })
    .required(ZIP_CODE_REQUIRED)
    .nullable(),
});

export type GiftType = {
  gifteeEmail?: string;
  gifteeMessage?: string;
  giftorName?: string;
};

// NO LOCALIZATION FOR NOW, ONLY USED IN V2 CHECKOUT
export const giftSchema: SchemaOf<{ giftForm: GiftType }> = object({
  giftForm: object().shape({
    gifteeEmail: string().trim().email(WRONG_EMAIL).required(FIELD_REQUIRED),
    gifteeMessage: string(),
    giftorName: string().required(FIELD_REQUIRED),
  }),
});

/* https://gist.github.com/manzoorwanijk/5993a520f2ac7890c3b46f70f6818e0a */
export const validateWithYup =
  (schema: AnyObjectSchema) =>
  async (values: unknown): Promise<ValidationErrors | undefined> => {
    try {
      await schema.validate(values, { abortEarly: false });
      // using explicit any here because the `schema` above is an AnyObjectSchema
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const errors = err?.inner?.reduce(
        (
          formError: Record<string, unknown>,
          innerError: { path: string; message: string }
        ) => {
          return setIn(formError, innerError.path, innerError.message);
        },
        {}
      );
      return errors;
    }
  };
